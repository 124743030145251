import * as React from 'react';
import {GridDensity} from './GridDensity';
import {GridAlignment} from './GridAlignment';

/**
 * @author ESTECO CSA Team <csa@esteco.com>
 */
interface GridProps {
    density?: GridDensity,
    alignment?: GridAlignment,
    mobileInverse?: boolean,
    additionalClasses?: string
}

const Grid: React.FC<GridProps> = (props) => (
    <div className={`l-grid  ${props.density ? props.density : ''}
        ${props.alignment ? props.alignment : ''}
        ${props.mobileInverse ? 'l-grid--mobile-inverse' : ''}
        ${props.additionalClasses ? props.additionalClasses : ''}`}>
        {props.children}
    </div>
);

export default Grid;
