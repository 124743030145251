import * as React from 'react';
import {IconSize} from './IconSize';

/**
 * @author ESTECO CSA Team <csa@esteco.com>
 */
interface SignProps {
    iconSize: IconSize,
    icon: string,
    label: string,
    reverse?: boolean
    inline?: boolean
}

const Sign: React.FC<SignProps> = (props) => (
    <div className={`soul-sign  soul-sign--regular  ${props.reverse ? 'soul-sign--rev' : ''} ${props.inline ? 'soul-sign--inline' : ''}`}>
        <span className="soul-sign__icon"><span className={`a-icon  ${props.iconSize}  ${props.icon}`}></span></span>
        <span className="soul-sign__text">{props.label}</span>
    </div>
);


export default Sign;
