import * as React from 'react';

/**
 * @author ESTECO CSA Team <csa@esteco.com>
 */

interface VerticalListProps {
    additionalClasses?: string;
}

const BlockList: React.FC<VerticalListProps> = ({additionalClasses, children}) => (
    <ol className={`l-block-list  l-block-list--stretched  l-block-list--transparent ${additionalClasses ? additionalClasses : ''}`}>
        {React.Children.map(children, one => <li className="l-block-list__item">{one}</li>)}
    </ol>
);

export default BlockList;
