import * as React from 'react';
import {Link} from 'gatsby';

/**
 * @author ESTECO CSA Team <csa@esteco.com>
 */
interface InternalLinkProps {
    to: string | undefined,
    enabled?: boolean;
    className?: string,
}

const InternalLink: React.FC<InternalLinkProps> = (props) => {
    const enabled = props.enabled == undefined ? true : props.enabled;
    if (enabled && props.to && props.to.length > 0) {
        return (
            <Link to={props.to} className={props.className}>
                {props.children}
            </Link>);
    } else {
        return <React.Fragment/>;
    }
};

export default InternalLink;
