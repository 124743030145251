import * as React from 'react';
import {useLocation} from '@reach/router';
import {graphql, useStaticQuery} from 'gatsby';
import Helmet from 'react-helmet';
import defaultImage from '../../assets/evento-um-2020-register-now.png';
import favicon from '../../assets/favicon.ico';

/**
 * @author ESTECO CSA Team <csa@esteco.com>
 */
interface SEOProps {
    title?: string;
    description?: string;
    image?: string;
    schemaMarkup?: any
}

interface SiteQueryResult {
    site: {
        siteMetadata: {
            defaultTitle: string;
            defaultDescription: string;
            siteUrl: string;
            twitterUsername: string;
            language: string;
            locale: string;
        }
    }
}

const SEO: React.FC<SEOProps> = (props) => {
    const {pathname} = useLocation();
    const {site}: SiteQueryResult = useStaticQuery(query);

    const {defaultTitle, defaultDescription, siteUrl, twitterUsername, language, locale} = site.siteMetadata;
    const completeImagePath = (image: string) => `${siteUrl}${image}`;
    const seo = {
        title: props.title || defaultTitle,
        description: props.description || defaultDescription,
        image: completeImagePath(props.image || defaultImage),
        url: `${siteUrl}${pathname}`,
        twitterUsername: twitterUsername,
        schemaMarkup: props.schemaMarkup,
        language: language,
        locale: locale
    };

    if (seo.schemaMarkup && seo.schemaMarkup.image) {
        seo.schemaMarkup.image = completeImagePath(seo.schemaMarkup.image);
    }

    return (
        <Helmet title={seo.title}
                htmlAttributes={{lang: seo.language}}
                link={[
                    {rel: 'shortcut icon', type: 'image/png', href: `${favicon}`}
                ]}
        >
            <meta name="description" content={seo.description}/>
            <meta name="image" content={seo.image}/>

            {/* Generic */}
            {seo.url && <meta property="og:url" content={seo.url}/>}
            {seo.title && <meta property="og:title" content={seo.title}/>}
            {seo.description && (<meta property="og:description" content={seo.description}/>)}
            {seo.image && <meta property="og:image" content={seo.image}/>}
            <meta name="og:locale" content={seo.locale}/>
            {seo.schemaMarkup && <script type="application/ld+json">{JSON.stringify(seo.schemaMarkup)}</script>}

            {/* Twitter */}
            <meta name="twitter:card" content="summary_large_image"/>
            {twitterUsername && (<meta name="twitter:creator" content={twitterUsername}/>)}
            {seo.title && <meta name="twitter:title" content={seo.title}/>}
            {seo.description && (<meta name="twitter:description" content={seo.description}/>)}
            {seo.image && <meta name="twitter:image" content={seo.image}/>}
        </Helmet>
    );
};

const query = graphql`
    query SEO {
        site {
            siteMetadata {
                defaultTitle: title
                defaultDescription: description
                siteUrl
                twitterUsername
                language
                locale
            }
        }
    }
`;

export default SEO;
