import * as React from 'react';
import BlockList from '../soul/listAndTables/BlockList';
import Grid from '../soul/layout/grid/Grid';
import {GridDensity} from '../soul/layout/grid/GridDensity';
import GridItem from '../soul/layout/grid/GridItem';
import {GridItemSize} from '../soul/layout/grid/GridItemSize';
import Sign from '../soul/layout/sign/Sign';
import {IconSize} from '../soul/layout/sign/IconSize';
import logoSvg from '../../assets/LOGO_ESTECO.svg';
import estecoLinksData from '../../content/links/esteco-links-content.json';
import socialLinksData from '../../content/links/social-links-content.json';
import umLinksData from '../../content/links/um-links-content.json';
import InternalLink from '../soul/navigation/InternalLink';
import NewsletterComponent from '../newsletter/Newsletter';

/**
 * @author ESTECO CSA Team <csa@esteco.com>
 */
const Footer: React.FC = () => {
    const filteredLinks = Object.values(umLinksData.links).filter(link => link.footer && link.enabled);

    return (
        <div className="footer">
            <div className="ew-newsletter soul-space-stack-both-s">
                <div className='centered-column'>
                    <NewsletterComponent name='' email=''/>
                </div>
            </div>

            <div className="centered-column">
                <Grid density={GridDensity.NARROW}>
                    <GridItem size={GridItemSize.ONE_QUARTER} additionalClasses='l-lap--one-half  l-palm--one-half'>
                        <div className="a-label a-label--section a-label--reduced soul-space-stack-both-s">{umLinksData.label}</div>
                        <BlockList>
                            {filteredLinks.map(link =>
                                <InternalLink key={link.href} to={link.href} enabled={link.enabled} className={'c-link soul-font-size-s'}>
                                    {link.label}
                                </InternalLink>
                            )}
                        </BlockList>
                    </GridItem>

                    <GridItem size={GridItemSize.ONE_QUARTER} additionalClasses='l-lap--one-half  l-palm--one-half'>
                        <div className="a-label a-label--section a-label--reduced soul-space-stack-both-s">{estecoLinksData.label}</div>
                        <BlockList>
                            {estecoLinksData.links.map(link =>
                                <a key={link.href} href={link.href} className="c-link soul-font-size-s">{link.label}</a>
                            )}
                        </BlockList>
                    </GridItem>

                    <GridItem size={GridItemSize.ONE_QUARTER} additionalClasses='l-lap--one-half  l-palm--one-half'>
                        <div className="a-label a-label--section a-label--reduced soul-space-stack-both-s">{socialLinksData.label}</div>
                        <BlockList>
                            {socialLinksData.links.map(link =>
                                <a key={link.href} href={link.href} className="c-link soul-font-size-s">
                                    <Sign icon={link.icon} iconSize={IconSize.ICON_M} label={link.label}/>
                                </a>
                            )}
                        </BlockList>
                    </GridItem>

                    <GridItem size={GridItemSize.ONE_QUARTER} additionalClasses='l-lap--one-half  l-palm--one-half'>
                        <div className="soul-space-stack-bottom-m">
                            <div className="soul-space-stack-top-s"/>
                            <img src={logoSvg} width="48px" alt=""/>
                            <div className="soul-space-stack-top-l">
                                <p className="a-label a-label--reduced a-label--inline">{estecoLinksData.rights.label}</p>
                            </div>
                            <div className="soul-space-stack-top-l">
                                <a href={estecoLinksData.privacy.href}
                                   className="c-link soul-font-size-s h-capitalize">{estecoLinksData.privacy.label}</a>
                            </div>
                            <div className="soul-space-stack-top-s">
                                <a href={estecoLinksData.terms.href} className="c-link soul-font-size-s">{estecoLinksData.terms.label}</a>
                            </div>
                        </div>
                    </GridItem>
                </Grid>
            </div>
        </div>
    );
}

export default Footer;
