/**
 * @author ESTECO CSA Team <csa@esteco.com>
 */
export enum GridItemSize {
    ONE_WHOLE = 'l-one-whole',
    ONE_HALF = 'l-one-half',
    ONE_THIRD = 'l-one-third',
    ONE_QUARTER = 'l-one-quarter',
    ONE_FIFTH = 'l-one-fifth',
    ONE_SIXTH = 'l-one-sixth',
    ONE_EIGHTH = 'l-one-eighth',
    ONE_TENTH = 'l-one-tenth',
    ONE_TWELFTH = 'l-one-twelfth',
    TWO_THIRDS = 'l-two-thirds',
    TWO_FIFTHS = 'l-two-fifths',
    TWO_EIGHTH = 'l-two-eighth',
    TWO_TENTH = 'l-two-tenth',
    TWO_TWELFTH = 'l-two-twelfth',
}
