import * as React from 'react';
import {GridItemSize} from './GridItemSize';

/**
 * @author ESTECO CSA Team <csa@esteco.com>
 */
interface GridItemProps {
    size?: GridItemSize,
    additionalClasses?: string
}

const GridItem: React.FC<GridItemProps> = (props) => (
    <div className={`l-grid__item  ${props.size ? props.size : ''}  ${props.additionalClasses}`}>
        {props.children}
    </div>
);

export default GridItem;
