import * as React from 'react';
import newsletterContent from '../../content/index/newsletter-content.json';
import estecoLinksData from '../../content/links/esteco-links-content.json';
import Grid from '../soul/layout/grid/Grid';
import GridItem from '../soul/layout/grid/GridItem';
import {GridItemSize} from '../soul/layout/grid/GridItemSize';
import addToMailchimp from 'gatsby-plugin-mailchimp';
import {GridAlignment} from '../soul/layout/grid/GridAlignment';

/**
 * @author ESTECO CSA Team <csa@esteco.com>
 */
interface NewsletterState {
    email: string,
    name: string,
    isEditing: boolean
    subscription_response?: {
        result: string
        msg: string
    }
}

interface NewsletterProps {
    email: string,
    name: string,
}

const FNAME_ID = 'mce-FNAME';
const EMAIL_ID = 'mce-EMAIL';

export default class NewsletterComponent extends React.Component<NewsletterProps, NewsletterState> {
    private readonly formRef: React.RefObject<HTMLFormElement>;

    constructor(props: NewsletterProps) {
        super(props);
        this.state = {email: props.email, name: props.email, isEditing: false};
        this.handleNameChange = this.handleNameChange.bind(this);
        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.formRef = React.createRef();
    }

    handleNameChange(event: any) {
        this.setState({...this.state, isEditing: true, name: event.target.value});
    }

    handleEmailChange(event: any) {
        this.setState({...this.state, isEditing: true, email: event.target.value});
    }


    async handleSubmit(event: any) {
        event.preventDefault();
        this.setState({...this.state, isEditing: true});
        if (this.formRef.current !== null && this.formRef.current.checkValidity()) {
            const result = await addToMailchimp(this.state.email, {'FNAME': this.state.name});
            this.setState({...this.state, subscription_response: result});
        }
    }

    render() {
        return (
            <div>
                <h2 className="soul-font-size-xl h-text-bold soul-space-stack-bottom-s">{newsletterContent.title}</h2>
                <p className="soul-font-size-m soul-space-stack-bottom-m">{newsletterContent.subtitle}</p>
                <form ref={this.formRef} id="mc-subscribe-to-newsletter" onSubmit={this.handleSubmit}>
                    <Grid alignment={GridAlignment.BOTTOM}>
                        <GridItem size={GridItemSize.TWO_FIFTHS} additionalClasses='l-palm--one-whole'>
                            <div className="m-field soul-space-stack-both-xs">
                                <div className="m-field__label">
                                    <label htmlFor={FNAME_ID} className="a-label a-label--field">
                                        {newsletterContent.input.name.label}
                                    </label>
                                </div>
                                <div className="m-field__value">
                                    <input required={this.state.isEditing} type="text" autoComplete="given-name"
                                           name="FNAME" id={FNAME_ID} className="a-input-text"
                                           value={this.state.name} onChange={this.handleNameChange}/>
                                </div>
                            </div>
                        </GridItem>
                        <GridItem size={GridItemSize.TWO_FIFTHS} additionalClasses='l-palm--one-whole'>
                            <div className="m-field soul-space-stack-both-xs">
                                <div className="m-field__label">
                                    <label htmlFor={EMAIL_ID}
                                           className="a-label a-label--field">{newsletterContent.input.email.label}</label>
                                </div>
                                <div className="m-field__value">
                                    <input required={this.state.isEditing} type="email" autoCapitalize="off"
                                           autoComplete="email"
                                           name="EMAIL" id={EMAIL_ID} className="a-input-text"
                                           value={this.state.email} onChange={this.handleEmailChange}/>
                                </div>
                            </div>
                        </GridItem>
                        <GridItem size={GridItemSize.ONE_FIFTH} additionalClasses='l-palm--one-whole'>
                            <div className="m-field soul-space-stack-both-xs">
                                <label className="h-visually-hidden">{newsletterContent.action}</label>
                                <input name="submit" type="submit" value="Subscribe"
                                       className="a-button a-button--primary a-button--full"/>
                            </div>
                        </GridItem>
                        <GridItem size={GridItemSize.ONE_WHOLE} additionalClasses='h-text-align-right'>
                            <div className={'soul-font-size-2-xs soul-space-inset-s'}>
                                {newsletterContent.action_info} <a href={estecoLinksData.privacy.href}>{estecoLinksData.privacy.label}</a>
                            </div>
                        </GridItem>
                        <GridItem size={GridItemSize.ONE_WHOLE}>
                            {this.state.subscription_response?.msg}
                        </GridItem>
                    </Grid>
                </form>
            </div>
        );
    }
}
